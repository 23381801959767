.admin-team-container {
  max-width: 100%;
  padding: 20px;
}

.add-member-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-member-button {
  padding: 10px 20px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-member-button:hover {
  background-color: #525252;
}

.team-heading {
  font-size: 24px;
  font-weight: bold;
  color: #4255d1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  
}

.team-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  
  gap: 20px;
}

.team-card {
  /* border: 1px solid #ffd166; */
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.team-member-details {
  text-align: center;
}

.team-member-name {
  font-size: 18px;
  font-weight: bold;
  color: #21295c;
  margin-bottom: 5px;
}

.team-member-email,
.team-member-phone {
  font-size: 14px;
  color: #666666;
}

@media screen and (max-width: 600px) {
  .team-cards-container {
    grid-template-columns: 1fr;
  }
}



