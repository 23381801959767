.medical-page {
    text-align: center;
    padding:0.4rem;
  }
  
  .record-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
  }
  
  .record {
    position: relative;
  }
  
  .record-photo {
    width: 90%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .record-photo:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .record-details {
    margin-top: 10px;
    text-align: center;
  }
  
  .record-patient-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .record-details-text {
    color: #555;
  }
  